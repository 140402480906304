<page-load-header *ngIf="!initialized"></page-load-header>

<ng-container *ngIf="initialized">
  <div *ngIf="subscriptionNote" class="row">
    <div class="col-12">
      <alert class="text-center" type="info" [dismissible]="false">
        <div>
          <span class="font-weight-bold">{{subscriptionNote}}</span>
        </div>
      </alert>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-3">
      <h3>Billing</h3>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body p-3">
          <div *ngIf="organization.stripeCustomerId" class="row">
            <div class="col mt-auto mb-auto">
              <h4>
                <i *ngIf="organization.cardBrand === 'American Express'" class="fab fa-cc-amex"></i>
                <i *ngIf="organization.cardBrand === 'Visa'" class="fab fa-cc-visa"></i>
                <i *ngIf="organization.cardBrand === 'MasterCard'" class="fab fa-cc-mastercard"></i> ending in {{ organization.last4OfCard }}
              </h4>
              <div *ngIf="organization.balance && organization.balance < 0">
                <b class="font-weight-500 text-lg text-green">Credit balance: {{(organization.balance * -1) | currency }}</b>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2"
                     title="The amount of money (credit) that automatically will be applied toward upcoming invoices."></i>
                </div>
              </div>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary" (click)="updateCard()">Update Card</button>
              <a *ngIf="organization.runnerSlotSubscriptionId && !organization.subscriptionEndDate" class="d-block text-sm mt-2" href="javascript:void(0)" (click)="cancelSubscription()">Cancel Subscription</a>
            </div>
          </div>
          <div *ngIf="!organization.runnerSlotSubscriptionId || organization.subscriptionEndDate" class="row mt-2">
            <div *ngIf="!organization.subscriptionEndDate" class="col mt-auto mb-auto">No active subscription</div>
            <div *ngIf="organization.subscriptionEndDate && (!organization.complimentarySubscription || (organization.complimentarySubscription && organization.complimentarySubscriptionEndDate))" class="col mt-auto mb-auto font-weight-500 text-lg text-red">Subscription ends on {{organization.subscriptionEndDate | date}}</div>
            <div class="col text-right">
              <button type="button" class="btn btn-danger" (click)="activateSubscription()">{{organization.subscriptionEndDate ? 'Re-activate' : 'Activate'}} Subscription</button>
            </div>
          </div>
          <ng-container *ngIf="organization.runnerSlotSubscriptionId">
            <hr />
            <div *ngIf="!pricingIsBeingRecalculated" class="row mt-3">
              <div class="col-md-9">

                <div class="font-weight-500 text-lg" *ngIf="!organization.product || (organization.product | flagEnumCompare:RunDnaProduct.RunnerCount)">
                  <b>RunDNA portal Athletes</b> pricing plan
                  <div class="d-inline text-center text-dark" kendoTooltip>
                    <i class="fas fa-info-circle ml-2"
                       title="Please note: The pricing for your account will automatically adjust based on the number of active athletes. Add or deactivate athletes to change count. Changes will reflect within 3 days from adjustments to athlete count."></i>
                  </div>
                </div>
                <div class="font-weight-500 text-lg" *ngIf="!organization.product || (organization.product | flagEnumCompare:RunDnaProduct.RunnerCount)">
                  <div class="text-gray">
                    Active Athletes: {{ organization.runnerSlots }}
                  </div>
                </div>
                <div class="font-weight-500 text-lg" *ngIf="organization.product && (organization.product | flagEnumCompare:RunDnaProduct.Helix)">
                  <b>RunDNA portal Helix</b> pricing plan
                  <div class="d-inline text-center text-dark" kendoTooltip>
                    <i class="fas fa-info-circle ml-2"
                       title="Subscription options for Helix customers: Flat rate of $17.99/month or $179/year"></i>
                  </div>
                </div>
                <div class="font-weight-300 text-lg text-green" *ngIf="organization.complimentarySubscription && organization.complimentarySubscriptionEndDate && !organization.subscriptionEndDate"> Complimentary Subscription until {{organization.complimentarySubscriptionEndDate | date}} then </div>
                <div class="font-weight-300 text-lg text-green" *ngIf="organization.complimentarySubscription && !organization.complimentarySubscriptionEndDate"> Complimentary Subscription </div>
                <div class="font-weight-500 text-lg" *ngIf="!organization.complimentarySubscription || (organization.complimentarySubscription && organization.complimentarySubscriptionEndDate)">{{getPricingDetails()}} </div>
              </div>
            </div>
            <div  *ngIf="pricingIsBeingRecalculated" class="row mt-3">
              <div class="col-md-12 text-center">
                <span class="k-icon k-i-loading text-primary" style="font-size: 33px;"></span>
              </div>
            </div>
            <div class="row mt-3" *ngIf="!organization.skipBillingUpdates && !organization.subscriptionEndDate && (!organization.complimentarySubscription || (organization.complimentarySubscription && organization.complimentarySubscriptionEndDate))">
              <div class="col-md-3">
                <input type="text" class="form-control" placeholder="Type here to apply/replace a coupon" [(ngModel)]="couponCode" name="couponCode" (ngModelChange)="validateCoupon()" aria-describedby="basic-addon1" />
                <div *ngIf="couponCode && !isCouponValid && !couponIsBeingValidated" class="invalid-message-visible">
                  <div>{{couponDescription}}</div>
                </div>
                <div *ngIf="couponCode && isCouponValid && !couponIsBeingValidated"  class="valid-message-visible">
                  <div>{{couponDescription}}</div>
                </div>
                <div *ngIf="couponIsBeingValidated" class="col-md-12 text-center">
                  <span class="k-icon k-i-loading text-primary" style="font-size: 15px;"></span>
                </div>
              </div>
              <div class="col-md-1">
                <button class="btn btn-primary" [disabled]="!couponCode || couponIsBeingValidated || pricingIsBeingRecalculated || !isCouponValid" (click)="replaceCoupon()">Apply</button>
              </div>
            </div>
          </ng-container>
          <div class="row mt-3" *ngIf="!organization.product || (organization.product | flagEnumCompare:RunDnaProduct.RunnerCount)">
            <div class="col">
              <a href="https://rundna.com/rundna-portal/#pricing" target="_blank">Pricing Tiers</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-3">
      <h3>Invoices</h3>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body p-3">
          <div class="row no-gutters no-candidates" *ngIf="invoices.length === 0">
            <div class="col-sm-12 my-auto">
              <h4>No Invoices</h4>
            </div>
          </div>
          <table class="table">
            <tr *ngFor="let invoice of invoices">
              <td>{{ invoice.periodEnd | date }}</td>
              <td>{{ invoice.status | titlecase }}</td>
              <td>{{ invoice.billingReason | titlecase }}</td>
              <td>{{ invoice.total | currency }}</td>
              <td><a href="{{invoice.invoicePdf}}" target="_blank">Download Invoice PDF</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
