<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Activate Subscription</h5>
  </div>
  <form [formGroup]="contactForm" (ngSubmit)="save()">
    <div class="modal-body">
      <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
      <div class="form-row mb-2">
        <div class="col-md-12">
          <label for="inputAddress">Billing Interval</label>
          <select class="form-control" (change)="onChangeIsMonthly()" formControlName="isMonthly">
            <option [ngValue]="true">Monthly</option>
            <option [ngValue]="false">Yearly</option>
          </select>
          <div class="invalid-message">Billing Interval is Required</div>
        </div>
      </div>

      <div *ngIf="organization.stripeCustomerId && organization.last4OfCard" class="form-row">
        <div class="col-md-12">
          <div class="form-group">
            <input class="single-checkbox" type="checkbox" [(ngModel)]="useCardOnFile" (ngModelChange)="onUseCardOnFileChange($event)" [ngModelOptions]="{standalone: true}" />
            <label class="form-control-label d-inline" for="useCardOnFile">
              Use card on file ending in {{organization.last4OfCard}}
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!useCardOnFile">
        <div class="form-row mb-2">
          <div class="col-md-9">
            <input type="text" class="form-control" placeholder="Card Number" formControlName="cardNumber" aria-label="Username" aria-describedby="basic-addon1" />
            <div class="invalid-message">
              <div>Must be a valid card number</div>
            </div>
          </div>
          <div class="col-md-3 mt-auto mb-auto">
            <img class="img-fluid" src="/assets/visa-mastercard-amex_0.png" />
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <select class="form-control" formControlName="expMo">
              <option value="1">01 - January</option>
              <option value="2">02 - February</option>
              <option value="3">03 - March</option>
              <option value="4">04 - April</option>
              <option value="5">05 - May</option>
              <option value="6">06 - June</option>
              <option value="7">07 - July</option>
              <option value="8">08 - August</option>
              <option value="9">09 - September</option>
              <option value="10">10 - October</option>
              <option value="11">11 - November</option>
              <option value="12">12 - December</option>
            </select>
            <div class="invalid-message">
              <div>Must be a valid month</div>
            </div>
          </div>
          <div class="col">
            <select class="form-control" formControlName="expYr">
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
              <option value="2035">2035</option>
              <option value="2036">2036</option>
              <option value="2037">2037</option>
            </select>
            <div class="invalid-message">
              <div>Must be a valid year</div>
            </div>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="cvv" formControlName="cvv" />
            <div class="invalid-message">
              <div>CVV is required</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-row mb-2 mt-2">
        <div class="col-md-12">
          <label for="inputAddress">Coupon (optional)</label>
          <input type="text" class="form-control" placeholder="Coupon code" (ngModelChange)="validateCoupon()" formControlName="coupon" aria-describedby="basic-addon1" />
          <div>
            <div *ngIf="!isCouponValid" class="invalid-message-visible">
              <div>{{couponDescription}}</div>
            </div>
            <div *ngIf="coupon && isCouponValid" class="valid-message-visible">
              <div>{{couponDescription}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mb-2 mt-2">
        <div class="col-md-12">
          <h5 *ngIf="tiers.product | flagEnumCompare:RunDnaProduct.Helix">RunDNA portal Helix</h5>
          <h5 *ngIf="tiers.product | flagEnumCompare:RunDnaProduct.RunnerCount">RunDNA portal Athletes</h5>
          <h6 *ngIf="tiers.product | flagEnumCompare:RunDnaProduct.RunnerCount" class="text-gray">Active Athletes: {{ tiers.qty }}</h6>
          <p *ngIf="tiers.product | flagEnumCompare:RunDnaProduct.RunnerCount" class="text-info">Please note: The pricing for your account will automatically adjust based on the number of active athletes. Changes will reflect within 3 days from adjustments to athlete count</p>
          <div *ngIf="!pricingIsBeingRecalculated">
            <h5>{{ getPricingDetails() }}</h5>
          </div>
          <div *ngIf="pricingIsBeingRecalculated" class="col-md-12 text-center">
            <span class="k-icon k-i-loading text-primary" style="font-size: 33px;"></span>
          </div>
        </div>
       </div>
      </div>
    <div class="modal-footer">
      <div class="col text-left pl-0">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cross click')">Close</button>
      </div>
      <div class="col text-right pr-0">
        <button type="submit" class="btn btn-primary" [disabled]=" pricingIsBeingRecalculated || (coupon && !isCouponValid)" [promiseBtn]="saving">Purchase</button>
      </div>
    </div>
  </form>
</div>
