import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HAXProgramsLayoutComponent } from './layouts/hax-programs-layout/hax-programs-layout.component';
import { IsAuthenticatedGuard } from './_guards/isAuthenticated.guard';
import { ClientsComponent } from './coaches-admin/Clients/clients/clients.component';
import { AddClientComponent } from './coaches-admin/Clients/clients/add-client.component';
import { LibraryComponent } from './coaches-admin/Library/library.component';
import { ProgramsComponent } from './coaches-admin/Library/programs/programs.component';
import { AccordionModule, BsDropdownModule, AlertModule } from 'ngx-bootstrap';
import { ListAssessmentsComponent } from './coaches-admin/Clients/assessments/list-assessments.component';
import { AddEditAssessmentComponent } from './coaches-admin/Clients/assessments/add-edit-assessment.component';
import { AddEditGaitCapacityAssessmentComponent } from './coaches-admin/Clients/assessments/add-edit-gaitcapacity-assessment.component';
import { RouterContainerComponent } from './_components/router-container/router-container.component';
import { ExercisesComponent } from './coaches-admin/Library/exercises/exercises.component';
import { AddEditExerciseComponent } from './coaches-admin/Library/exercises/add-edit/add-edit-exercise.component';
import { WorkoutsComponent } from './coaches-admin/Library/workouts/workouts.component';
import { ExerciseListComponent } from './coaches-admin/Library/shared/exercise-list.component';
import { SecsToHourMinSecPipe, SingularPipe, CompletionPercentRangeColorPipe, BooleanToTextPipe, BooleanToYesNoPipe, MinutesDecimalToStringPipe, AcuteChronicRatioColorPipe, AcuteChronicRatioTextPipe, flagEnumComparePipe } from './_helpers/extensions.module';
import { EditExerciseComponent } from './coaches-admin/Library/shared/edit-exercise.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AddEditWorkoutComponent } from './coaches-admin/Library/workouts/add-edit/add-edit-workout.component';
import { MyProfileComponent } from './_components/my-user-settings/my-profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ExerciseDropdownControl } from './_controls/exercisesDropdown';
import { AddEditProgramComponent } from './coaches-admin/Library/programs/add-edit/add-edit-program.component';
import { WorkoutDropdownControl } from './_controls/workoutsDropdown';
import { AdministrationComponent } from './coaches-admin/administration/administration.component';
import { AdminSettingsComponent } from './coaches-admin/administration/settings/admin-settings.component';
import { AdminBillingComponent } from './coaches-admin/administration/billing/admin-billing.component';
import { AdminUsersComponent } from './coaches-admin/administration/users/admin-users.component';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { RunnerDropdownControl } from './_controls/runnersDropdown';
import { AssignProgramComponent } from './_components/assign-program/assign-program.component';
import { ClientsContainerComponent } from './coaches-admin/Clients/clients-container.component';
import { ClientCalendarComponent } from './coaches-admin/Clients/calendar/client-calendar.component';
import { CalendarWorkoutDialogComponent } from './_components/calendar-workout-dialog/calendar-workout-dialog.component';
import { ClientsListContainerComponent } from './coaches-admin/Clients/clients/clients-list-container.component';
import { CoachesDashboardComponent } from './coaches-admin/Dashboard/coaches-dashboard.component';
import { ClientDashboardComponent } from './coaches-admin/Clients/dashboard/client-dashboard.component';
import { RunnerViewComponent } from './runners/runner-calendar/runner-view.component';
import { RunnerContainerComponent } from './runners/runner-container.component';
import { RunnerDashboardComponent } from './runners/runner-dashboard/runner-dashboard.component';
import { RunnerWorkoutComponent } from './runners/runner-workout/runner-workout.component';
import { RunnerAssessmentsComponent } from './runners/runner-assessments/runner-assessments.component';
import { SuperUserComponent } from './coaches-admin/SuperUser/superuser.component';
import { SuperUserProgramComponent } from './coaches-admin/SuperUser/programs/superuser-program.component';
import { ProgramDropdownControl } from './_controls/programsDropdown';
import { AssessmentProgramDialogComponent } from './_components/assessment-program-assignment/assessment-program-dialog.component';
import { GaitCapabilityAssessmentProgramDialogComponent } from './_components/assessment-program-assignment/gaitcapability-assessment-program-dialog.component';
import { WorkoutItemViewComponent } from './_components/workout-item-view/workout-item-view.component';
import { ViewGaitAnalysisComponent } from './runners/runner-assessments/view-gait-analysis/view-gait-analysis.component';
import { ViewGaitAnalysisWalkingComponent } from './runners/runner-assessments/view-gait-analysis/view-gait-analysis-walking.component';
import { ViewGaitComparisonReportComponent } from './runners/runner-assessments/view-gait-analysis/view-gait-comparison-report.component';
import { AssessmentTooltipListComponent } from './coaches-admin/SuperUser/assessmentTooltips/assessment-tooltip-list.component';
import { AddEditAssessmentTooltipComponent } from './coaches-admin/SuperUser/assessmentTooltips/add-edit/add-edit-assessment-tooltip.component';
import { ExerciseToolTipModalComponent } from './coaches-admin/Clients/assessments/exercise-tool-tip-modal.component';
import { GaitReportVideoPopupComponent } from './_components/gait-analysis-result-details/gait-report-video-popup.component';
import { GaitComparisonReportComponent } from './_components/gait-comparison-report/gait-comparison-report.component';
import { GaitReportDiagramPopupComponent } from './_components/gait-analysis-result-details/gait-report-diagram-popup.component';
import { RunnerWorkoutViewComponent } from './_components/runner-workout-view/runner-workout-view.component';
import { RunnerWorkoutModalComponent } from './_components/runner-workout-modal/runner-workout-modal.component';
import { CalendarDayDetailsComponent } from './_components/calendar-day-details/calendar-day-details.component';
import { CompleteRegistrationComponent } from './login-signup/complete-registration/complete-registration.component';
import { RunnerProgramDetailsComponent } from './_components/runner-program-details/runner-program-details.component';
import { RunnerDashboardViewComponent } from './_components/runner-dashboard-view/runner-dashboard-view.component';
import { ConfirmModalComponent } from './_components/confirm-modal/confirm-modal.component';
import { BreadcrumbsComponent } from './_components/breadcrumbs/breadcrumbs.component';
import { ImageUploadModalComponent } from './_components/image-upload-modal/image-upload-modal.component';
import { StateDropdownControl } from './_controls/stateDropdown';
import { ViewAssessmentComponent } from './runners/runner-assessments/view-assessment/view-assessment.component';
import { ViewGaitCapabilityAssessmentComponent } from './runners/runner-assessments/view-gaitcapability-assessment/view-gaitcapability-assessment.component';
import { AssessmentResultDetailsComponent } from './_components/assessment-result-details/assessment-result-details.component';
import { GaitCapabilityAssessmentResultDetailsComponent } from './_components/gaitcapability-assessment-result-details/gaitcapability-assessment-result-details.component';
import { AssessmentListViewComponent } from './_components/assessment-list-view/assessment-list-view.component';
import { AssessmentDetailsComponent } from './coaches-admin/Clients/assessments/view-assessment/assessment-details.component';
import { GaitCapabilityAssessmentDetailsComponent } from './coaches-admin/Clients/assessments/view-assessment/gaitcapability-assessment-details.component';
import { GaitAnalysisDetailsComponent } from './coaches-admin/Clients/assessments/view-gait-anaysis/gait-analysis-details.component';
import { GaitAnalysisWalkingDetailsComponent } from './coaches-admin/Clients/assessments/view-gait-anaysis/gait-analysis-walking-details.component';
import { StatusBarComponent } from './_components/gait-analysis-result-details/status-bar.component';
import { GaitAnalysisResultDetailsComponent } from './_components/gait-analysis-result-details/gait-analysis-result-details.component';
import { GaitAnalysisWalkingResultDetailsComponent } from './_components/gait-analysis-result-details/gait-analysis-walking-result-details.component';
import { ExercisePreviewComponent } from './coaches-admin/Library/shared/exercise-preview/exercise-preview.component';
import { OrganizationListComponent } from './coaches-admin/SuperUser/organizations/organization-list.component';
import { AddEditOrganizationComponent } from './coaches-admin/SuperUser/organizations/add-edit/add-edit-organization.component';
import { DailyStatusModalComponent } from './_components/runner-daily-status/daily-status-modal.component';
import { RunnerDailyStatusesComponent } from './_components/runner-daily-statuses/runner-daily-statuses.component';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { ClientProfileComponent } from './coaches-admin/Clients/clients/client-profile.component';
import { CoachDropdownControl } from './_controls/coachesDropdown';
import { WorkoutViewComponent } from './_components/workout-view/workout-view.component';
import { WorkoutViewModalComponent } from './_components/workout-view-modal/workout-view-modal.component';
import { IntegrationsComponent } from './_components/my-user-settings/integrations/integrations.component';
import { ExternalWorkoutRouteMapComponent } from './_components/external-workout-route-map/external-workout-route-map.component';
import { ClientWorkoutComponent } from './coaches-admin/Clients/workout/client-workout.component';
import { ExternalWorkoutSummaryComponent } from './_components/external-workout-summary/external-workout-summary.component';
import { ExternalElevationChartComponent } from './_components/external-workout-charts/elevation-chart/elevation-chart.component';
import { ExternalHeartRateChartComponent } from './_components/external-workout-charts/heart-rate-chart/heart-rate-chart.component';
import { ExternalPaceChartComponent } from './_components/external-workout-charts/pace-chart/pace-chart.component';
import { ExternalCadenceChartComponent } from './_components/external-workout-charts/cadence-chart/cadence-chart.component';
import { CustomEditorControl } from './_controls/customEditor';
import { ExternalStrideLengthChartComponent } from './_components/external-workout-charts/stride-length-chart/stride-length-chart.component';
import { RoleGuard } from './_guards/role.guard';
import { UserRole } from './_models/generatedModels';
import { RestingHeartRateChartComponent } from './_components/external-daily-charts/resting-heart-rate-chart/resting-heart-rate-chart.component';
import { AddLoadingLevelsComponent } from './coaches-admin/Clients/assessments/add-loading-levels/add-loading-levels.component';
import { LoadingLevelsProgramsComponent } from './coaches-admin/SuperUser/loading-levels-programs/loading-levels-programs.component';
import { LoadingLevelsDetailsComponent } from './coaches-admin/Clients/assessments/view-loading-levels/loading-levels-details.component';
import { LoadingLevelsResultDetailsComponent } from './_components/loading-levels-result-details/loading-levels-result-details.component';
import { ViewLoadingLevelsComponent } from './runners/runner-assessments/view-loading-levels/view-loading-levels.component';
import { LoadingLevelsProgramDialogComponent } from './_components/loading-levels-program-assignment/loading-levels-program-dialog.component';
import { AssessmentNotesDialogComponent } from './_components/assessment-notes-dialog/assessment-notes-dialog.component';
import { ActivateSubscription } from './coaches-admin/administration/billing/activate-subscription/activate-subscription.component';
import { UpdateCard } from './coaches-admin/administration/billing/update-card/update-card.component';
import { VideoUploadComponent } from './_components/video-upload/video-upload.component';
import { Add2DAssessmentComponent } from './coaches-admin/Clients/assessments/add-2d-assessment/add-2d-assessment.component';
import { MultiOptionControl } from './_components/multiOptionControl/multiOptionControl.component';
import { ImageUploadComponent } from './_components/image-upload/image-upload.component';
import { TwoDimensionalAssessmentResultDetailsComponent } from './_components/two-dimensional-result-details/two-dimensional-result-details.component';
import { TwoDimensionalAssessmentDetailsComponent } from './coaches-admin/Clients/assessments/view-two-dimensional-assessment/two-dimensional-assessment-details.component';
import { View2DAssessmentComponent } from './runners/runner-assessments/view-2d-assessment/view-2d-assessment.component';
import { TwoDimensionalProgramDialogComponent } from './_components/two-dimensional-program-assignment/two-dimensional-program-dialog.component';
import { RunningCategoryChangeDialogComponent } from './_components/running-category-change-dialog/running-category-change-dialog.component';
import { GaitReportComparisonDialogComponent } from './_components/gait-comparison-report/gait-reports-comparison-dialog.component';
import { ReportsComponent } from './coaches-admin/Reports/reports.component';
import { ReportCompletionPercentageComponent } from './coaches-admin/Reports/completion-percentage/completion-percentage.component';
import { CoachAthleteGroupDropdownControl } from './_controls/coachAthleteGroupDropdown';
import { ReportTrainingVolumeComponent } from './coaches-admin/Reports/training-volume/training-volume.component';
import { RunnerWorkoutConfirmationComponent } from './runners/runner-workout-confirmation/runner-workout-confirmation.component';
import { DonutPieChartComponent } from './_controls/donutPieChart/donut-pie-chart.component';
import { AddEditWorkoutComponentComponent } from './_components/workout-add-edit/add-edit-workout.component';
import { WorkoutAddModalComponent } from './_components/workout-add-modal/workout-add-modal.component';
import { IsSuperUserGuard } from './_guards/isSuperUser.guard';
import { ShareWorkoutDialogComponent } from './_components/share-workout-dialog/share-workout-dialog.component';
import { ClientTrainingIdeasComponent } from './coaches-admin/Clients/training-ideas/client-training-ideas.component';
import { RunnerMyLibraryViewComponent } from './_components/runner-my-library/runner-my-library.component';
import { RunnerTrainingIdeasComponent } from './runners/runner-training-ideas/runner-training-ideas.component';
import { WorkoutAddToCalendarComponent } from './_components/workout-add-to-calendar-dialog/workout-add-to-calendar.component';
import { MessageListComponent } from './coaches-admin/messaging/message-list/message-list.component';
import { SendMessageModalComponent } from './coaches-admin/messaging/send-message/send-message-modal.component';
import { IntensityRPERangeChartComponent } from './_components/charts/IntensityRPERange/intensity-rpe-range-chart.component';
import { DailyStatusListComponent } from './_components/daily-status-list/daily-status-list.component';
import { ClientDailyStatusesComponent } from './coaches-admin/Clients/daily-statuses/client-daily-statuses.component';
import { RunnerDailyStatusesListComponent } from './runners/runner-daily-statuses-list/runner-daily-statuses-list.component';
import { ClientTrainingVolumeComponent } from './coaches-admin/Clients/training-volume/client-training-volume.component';
import { TrainingVolumeListComponent } from './_components/training-volume-list/training-volume-list.component';
import { RunnerTrainingVolumeListComponent } from './runners/runner-training-volume-list/runner-training-volume-list.component';
import { IsOrgActiveGuard } from './_guards/isOrgActive.guard';
import { OrganizationInactiveComponent } from './login-signup/organization-inactive/organization-inactive.component';
import { IsUserActiveGuard } from './_guards/isUserActive.guard';
import { AddAirForceFitnessComponent } from './_components/af-fitness-add/add-air-force-fitness.component';
import { AFFitnessResultDetailsComponent } from './_components/af-fitness-result-details/af-fitness-result-details.component';
import { AFFitnessDetailsComponent } from './coaches-admin/Clients/assessments/view-air-force-fitness/af-fitness-details.component';
import { ViewAFFitnessComponent } from './runners/runner-assessments/view-air-force-fitness/view-af-fitness.component';
import { ManualWorkoutModalComponent } from './_components/manual-workout-modal/manual-workout-modal.component';
import { NotificationsComponent } from './_components/my-user-settings/notifications/notifications.component';
import { AddEditPlannerComponent } from './coaches-admin/Clients/planner/add-edit-planner.component';
import { ListTrainingLoadPlannerComponent } from './coaches-admin/Clients/planner/list-planners.component';
import { TrainingLoadPlanCloneDialogComponent } from './_components/training-load-plan-clone/training-load-plan-clone.component';
import { DateRangeDialogComponent } from './_components/date-range-dialog/date-range-dialog.component';
import { TrainingVolumeTableComponent } from './_components/reports/training-volume-table/training-volume-table.component';
import { AddEditRacePlanComponent } from './coaches-admin/Clients/race-plan/add-edit-race-plan.component';
import { ListRacePlanComponent } from './coaches-admin/Clients/race-plan/list-race-plans.component';
import { RacePlanCloneDialogComponent } from './_components/race-plan-clone/race-plan-clone.component';
import { RunnerPlansComponent } from './runners/runner-plans/runner-plans.component';
import { RacePlanViewModalComponent } from './_components/race-plan-view-modal/race-plan-view-modal.component';
import { TrainingPlanViewModalComponent } from './_components/training-plan-view-modal/training-plan-view-modal.component';
import { ClientAcuteChronicComponent } from './coaches-admin/Clients/acute-chronic/client-acute-chronic.component';
import { AcuteChronicListComponent } from './_components/acute-chronic-list/acute-chronic-list.component';
import { RunnerAcuteChronicListComponent } from './runners/runner-acute-chronic-list/runner-acute-chronic-list.component';
import { CustomCalendarComponent } from './_components/custom-calendar/custom-calendar.component';
import { MinutesSecondsTextbox } from './_controls/minutesSecondsTextbox';
import { DeleteCalendarEventDialogComponent } from './_components/calendar-event-delete-dialog/delete-calendar-event-dialog.component';
import { MoveCalendarEventDialogComponent } from './_components/calendar-event-move-dialog/move-calendar-event-dialog.component';
import { CalendarEventToggleCompleteDialogComponent } from './_components/calendar-event-toggle-complete-dialog/calendar-event-toggle-complete-dialog.component';
import { DirectivesModule } from './directives.module';
import { TodaysWorkoutsComponent } from './_components/todays-workouts/todays-workouts.component';
import { CustomInputPersistedControl } from './_controls/customInputPersisted';
import { TeamContainerComponent } from './coaches-admin/Teams/team-container/team-container.component';
import { TeamCalendarComponent } from './coaches-admin/Teams/team-calendar/team-calendar.component';
import { AthleteCalendarComponent } from './_components/athlete-calendar/athlete-calendar.component';
import { TeamsContainerComponent } from './coaches-admin/Teams/teams-container.component';
import { TeamsDashboardComponent } from './coaches-admin/Teams/teams-dashboard/teams-dashboard.component';
import { TeamDashboardComponent } from './coaches-admin/Teams/team-dashboard/team-dashboard.component';
import { TeamDropdownControl } from './_controls/teamsDropdown';
import { AssignTeamProgramComponent } from './_components/assign-team-program/assign-team-program.component';
import { ManageTeamDialogComponent } from './_components/manage-team-dialog/manage-team-dialog.component';
import { RunnerBulkSelectControl } from './_controls/runnersBulkSelect';
import { CoachMultiselectControl } from './_controls/coachesMultiselect';
import { TeamMultiselectControl } from './_controls/teamsMultiselect';
import { TeamProgramDetailsComponent } from './_components/team-program-details/team-program-details.component';
import { ExternalLapChartComponent } from './_components/external-workout-charts/lap-chart/lap-chart.component';
import { RebuildReportingComponent } from './coaches-admin/SuperUser/rebuild-reporting/rebuild-reporting.component';
import { CopyCalendarEventDialogComponent } from './_components/calendar-event-copy-dialog/copy-calendar-event-dialog.component';
import { WorkoutProgressionsComponent } from './coaches-admin/Library/workout-progressions/workout-progressions.component';
import { AddEditWorkoutProgressionComponent } from './coaches-admin/Library/workout-progressions/add-edit/add-edit-workout-progression.component';
import { WorkoutProgressionPreviewComponent } from './coaches-admin/Library/workout-progressions/preview/workout-progression-preview.component';
import { WorkoutLevelChangeModalComponent } from './_components/workout-level-change-modal/workout-level-change-modal.component';
import { AdminUserAddEditModalComponent } from './_components/admin-user-add-edit-modal/admin-user-add-edit-modal.component';
import { SharedModule } from './shared.module';
import { CopyProgramEventDialogComponent } from './coaches-admin/Library/programs/copy-program-event-dialog/copy-program-event-dialog.component';
import { AddProgramEventDialogComponent } from './coaches-admin/Library/programs/add-edit/add-program-event-dialog/add-program-event-dialog.component';
import { AllUserListComponent } from './coaches-admin/SuperUser/users/user-list.component';
import { SuperuserStatsComponent } from './coaches-admin/SuperUser/stats/stats.component';
import { RunnerAddSurveyComponent } from './runners/runner-assessments/runner-surveys/add-survey/runner-add-survey.component';
import { AssessmentsContainerComponent } from './runners/runner-assessments/assessments-container.component';
import { SurveyListComponent } from './coaches-admin/SuperUser/surveys/survey-list.component';
import { EditSurveyModalComponent } from './coaches-admin/SuperUser/surveys/edit-survey-modal/edit-survey-modal.component';
import { SuperuserProgramDropdownControl } from './_controls/superuserProgramsDropdown';
import { ChangePasswordDialogComponent } from './_components/change-password-dialog/change-password-dialog.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ProgramRescheduleDialogComponent } from './_components/program-reschedule-dialog/program-reschedule-dialog.component';
import { ExternalSpeedChartComponent } from './_components/external-workout-charts/speed-chart/speed-chart.component';
import { CanDeactivateGuard } from './_guards/canDeactivate.guard';
import { CoachLibraryDropdownPersistedControl } from './_controls/coachesLibraryDropdownPersisted';
import { IsAthleteActiveGuard } from './_guards/isAthleteActive.guard';
import { PendingInvitationsComponent } from './login-signup/pending-invitations/pending-invitations.component';
import { UserDropdownControl } from './_controls/usersDropdown';
import { TermsAndConditionsComponent } from './public/terms/terms-and-conditions.component';
import { PrintCalendarDialogComponent } from './_components/print-calendar-dialog/print-calendar-dialog.component';
import { WorkoutViewPDFComponent } from './_components/workout-view-pdf/workout-view-pdf.component';
import { PrintWorkoutDialogComponent } from './_components/print-workout-dialog/print-workout-dialog.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { ProgramQuickWorkoutViewModalComponent } from './_components/program-quick-workout-view-modal/program-quick-workout-view-modal.component';
import { QuickWorkoutViewPDFComponent } from './_components/quick-workout-view-pdf/quick-workout-view-pdf.component';
import { QuickWorkoutViewComponent } from './_components/quick-workout-view/quick-workout-view.component';
import { QuickWorkoutViewModalComponent } from './_components/quick-workout-view-modal/quick-workout-view-modal.component';
import { AssessmentPDFDialogComponent } from './_components/assessment-pdf-dialog/assessment-pdf-dialog.component';
import { AzureFileUploadControl } from './_controls/sizedImageUpload';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { GaitAnalysisUpdateDialogComponent } from './_components/gait-analysis-update-dialog/gait-analysis-update-dialog.component';
import { GaitCapacityReportUpdateDialogComponent } from './_components/gait-analysis-update-dialog/gait-capacity-report-update-dialog.component'; 
import { RunnerReadinessReportUpdateUpdateDialogComponent } from './_components/gait-analysis-update-dialog/runner-readiness-report-update-dialog.component';
import { GaitReportUpdateDialogComponent } from './_components/gait-analysis-update-dialog/gait-report-update-dialog.component';
import { MatchSyncedWorkoutDialogComponent } from './_components/match-synced-workout-dialog/match-synced-workout-dialog.component';
import { AdminMarketplaceComponent } from './coaches-admin/administration/marketplace/admin-marketplace.component';
import { OrgMarketplaceLayoutComponent } from './layouts/org-marketplace-layout/org-marketplace-layout.component';
import { GlobalMarketplaceLayoutComponent } from './layouts/global-marketplace-layout/global-marketplace-layout.component';
import { FlagEnumButtonsControl } from './_controls/flagEnumButtons/flagEnumButtonsControl.component';
import { ProgramAddToCalendarComponent } from './_components/program-add-to-calendar-dialog/program-add-to-calendar.component';
import { GlobalMarketplaceProgramListComponent } from './global-marketplace/global-marketplace-program-list/global-marketplace-program-list.component';
import { ExerciseProgressionPreviewComponent } from './coaches-admin/Library/exercise-progressions/preview/exercise-progression-preview.component';
import { ExerciseProgressionsComponent } from './coaches-admin/Library/exercise-progressions/exercise-progressions.component';
import { AddEditExerciseProgressionComponent } from './coaches-admin/Library/exercise-progressions/add-edit/add-edit-exercise-progression.component';
import { ExerciseAddModalComponent } from './_components/exercise-add-modal/exercise-add-modal.component';
import { ExerciseLevelChangeModalComponent } from './_components/exercise-level-change-modal/exercise-level-change-modal.component';
import { ExerciseViewComponent } from './_components/exercise-view/exercise-view.component';
import { PrintExerciseDialogComponent } from './_components/print-exercise-dialog/print-exercise-dialog.component';
import { AcuteChronicDonutChartComponent } from './_components/charts/acute-chronic-donut-chart/acute-chronic-donut-chart.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { WorkoutItemDetailViewComponent } from './_components/workout-item-detail-view/workout-item-detail-view.component';
import { CopyCalendarWeekDialogComponent } from './_components/calendar-week-copy-dialog/copy-calendar-week-dialog.component';
import { ShareExerciseDialogComponent } from './_components/share-exercise-dialog/share-exercise-dialog.component';
import { GaitAnalysisSharedComponent } from './public/gait-analysis-shared/gait-analysis-shared.component';
import { GaitAnalysisWalkingSharedComponent } from './public/gait-analysis-shared/gait-analysis-walking-shared.component';
import { GaitComparisonReportSharedComponent } from './public/gait-analysis-shared/gait-comparison-report-shared.component';
import { GaitCapabilityAnalysisSharedComponent } from './public/gait-analysis-shared/gait-capability-analysis-shared.component';
import { RunnerMyMessagesViewComponent } from './_components/runner-my-messages/runner-my-messages.component';
import { ClientMessagesComponent } from './coaches-admin/Clients/messages/client-messages.component';
import { ClientNotificationsComponent } from './_components/my-user-settings/notifications/client-notifications.component';
import { ClientJournalComponent } from './coaches-admin/Clients/journal/client-journal.component';
import { RunnerJournalViewComponent } from './_components/runner-journal-view/runner-journal-view.component';
import { GeneralJournalDialogComponent } from './_components/general-journal-dialog/general-journal-dialog.component';
import { RunnerJournalComponent } from './runners/runner-journal/runner-journal.component';

const routes: Routes = [

{
  path: 'workout-complete-confirmation/:id',
  component: RunnerWorkoutConfirmationComponent
},
{
  path: 'pending-invitations',
  component: PendingInvitationsComponent
},
{
  path: 'complete-registration',
  component: CompleteRegistrationComponent,
},
{
  path: 'org-inactive',
  component: OrganizationInactiveComponent,
},
{
  path: 'terms',
  component: TermsAndConditionsComponent,
},
{
  path: 'privacy',
  component: PrivacyPolicyComponent,
},
{
  path: 'gait-analysis/:id',
  component: GaitAnalysisSharedComponent,
  },
  {
    path: 'gait-walking/:id',
    component: GaitAnalysisWalkingSharedComponent,
  },
  {
    path: 'gait-comparison/:rid/:secrid',
    component: GaitComparisonReportSharedComponent,
  },
  {
    path: 'gait-capability-assessment/:id',
    component: GaitCapabilityAnalysisSharedComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [IsAuthenticatedGuard],
    canActivateChild: [IsUserActiveGuard],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: CoachesDashboardComponent,
        canActivate: [IsOrgActiveGuard, RoleGuard],
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
      },
      {
        path: 'athletes',
        component: ClientsListContainerComponent,
        canActivate: [RoleGuard],
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'list', pathMatch:'full'},
          {path: 'list', component: ClientsComponent, canActivate: [IsOrgActiveGuard]},
          {path: 'add', component: AddClientComponent, canActivate: [IsOrgActiveGuard]},
        ]
      },
      {
        path: 'athletes/details/:id',
        component: ClientsContainerComponent,
        canActivate: [IsOrgActiveGuard, IsAthleteActiveGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'dashboard', pathMatch:'full'},
          {path: 'calendar', component: ClientCalendarComponent},
          {path: 'dashboard', component: ClientDashboardComponent},
          {path: 'workout/:id', component: ClientWorkoutComponent},
          {path: 'daily-statuses', component: ClientDailyStatusesComponent},
          {path: 'training-volume', component: ClientTrainingVolumeComponent},
          {path: 'acute-chronic', component: ClientAcuteChronicComponent},
          {
            path: 'assessments',
            component: RouterContainerComponent,
            children:[
              {path: '', redirectTo: 'list', pathMatch:'full'},
              {path: 'list', component: ListAssessmentsComponent},
              {path: 'rra-assessment/add', component: AddEditAssessmentComponent, canDeactivate: [CanDeactivateGuard]},
              {path: 'rra-assessment/edit/:id', component: AddEditAssessmentComponent, canDeactivate: [CanDeactivateGuard]},
              {path: 'rra-assessment/view/:id', component: AssessmentDetailsComponent },
              {path: 'gc-assessment/add', component: AddEditGaitCapacityAssessmentComponent, canDeactivate: [CanDeactivateGuard] },
              {path: 'gc-assessment/edit/:id', component: AddEditGaitCapacityAssessmentComponent, canDeactivate: [CanDeactivateGuard] },
              {path: 'gc-assessment/view/:id', component: GaitCapabilityAssessmentDetailsComponent },
              {path: 'analysis/view/:id', component: GaitAnalysisDetailsComponent},
              {path: 'analysis-walking/view/:id', component: GaitAnalysisWalkingDetailsComponent },
              {path: 'gait-comparison/view/:rid/:secrid', component: GaitComparisonReportComponent },
              {path: 'loading-levels/add', component: AddLoadingLevelsComponent, canDeactivate: [CanDeactivateGuard]},
              {path: 'loading-levels/edit/:id', component: AddLoadingLevelsComponent, canDeactivate: [CanDeactivateGuard]},
              {path: 'loading-levels/view/:id', component: LoadingLevelsDetailsComponent},
              {path: '2d-assessment/add', component: Add2DAssessmentComponent, canDeactivate: [CanDeactivateGuard]},
              {path: '2d-assessment/edit/:id', component: Add2DAssessmentComponent, canDeactivate: [CanDeactivateGuard]},
              {path: '2d-assessment/view/:id', component: TwoDimensionalAssessmentDetailsComponent},
              {path: 'af-fitness-assessment/add', component: AddAirForceFitnessComponent, canDeactivate: [CanDeactivateGuard]},
              {path: 'af-fitness/view/:id', component: AFFitnessDetailsComponent},
            ]
          },
          {path: 'athlete-profile', component: ClientProfileComponent},
          {path: 'my-library', component: ClientTrainingIdeasComponent},
          {path: 'planner', component: ListTrainingLoadPlannerComponent},
          {path: 'planner/add', component: AddEditPlannerComponent},
          {path: 'planner/edit/:id', component: AddEditPlannerComponent},
          {path: 'race-plan', component: ListRacePlanComponent},
          {path: 'race-plan/add', component: AddEditRacePlanComponent},
          {path: 'race-plan/edit/:id', component: AddEditRacePlanComponent},
          {path: 'athlete-messages', component: ClientMessagesComponent},
          {path: 'athlete-notifications', component: ClientNotificationsComponent },
          {path: 'athlete-journal', component: ClientJournalComponent},
        ]
      },
      {
        path: 'teams',
        component: TeamsContainerComponent,
        canActivate: [IsOrgActiveGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'dashboard', pathMatch:'full'},
          {path: 'dashboard', component: TeamsDashboardComponent},
        ]
      },
      {
        path: 'teams/details/:id',
        component: TeamContainerComponent,
        canActivate: [IsOrgActiveGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'dashboard', pathMatch:'full'},
          {path: 'dashboard', component: TeamDashboardComponent},
          {path: 'calendar', component: TeamCalendarComponent},
        ]
      },
      {
        path: 'library',
        component: LibraryComponent,
        canActivate: [IsOrgActiveGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'workouts', pathMatch:'full'},
          {path: 'exercises', component: ExercisesComponent},
          {path: 'exercises/add', component: AddEditExerciseComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'exercises/edit/:id', component: AddEditExerciseComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'programs', component: ProgramsComponent},
          {path: 'programs/add', component: AddEditProgramComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'programs/edit/:id', component: AddEditProgramComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'programs/view/:id', component: AddEditProgramComponent},
          {path: 'workouts', component: WorkoutsComponent},
          {path: 'workouts/add/:workoutType', component: AddEditWorkoutComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'workouts/edit/:id', component: AddEditWorkoutComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'workout-progressions', component: WorkoutProgressionsComponent},
          {path: 'workout-progressions/add', component: AddEditWorkoutProgressionComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'workout-progressions/edit/:id', component: AddEditWorkoutProgressionComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'exercise-progressions', component: ExerciseProgressionsComponent},
          {path: 'exercise-progressions/add', component: AddEditExerciseProgressionComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'exercise-progressions/edit/:id', component: AddEditExerciseProgressionComponent, canDeactivate: [CanDeactivateGuard]},
        ]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [IsOrgActiveGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Administrator, UserRole.AssistantCoach, UserRole.HeadCoach, UserRole.GlobalCoach] },
        children:[
          {path: '', redirectTo: 'completion-percentage', pathMatch:'full'},
          {path: 'completion-percentage', component: ReportCompletionPercentageComponent},
          {path: 'training-volume', component: ReportTrainingVolumeComponent}
        ]
      },
      {
        path: 'admin',
        component: AdministrationComponent,
        canActivate: [RoleGuard],
        data: { roles: [UserRole.Administrator] },
        children:[
          {path: '', redirectTo: 'users', pathMatch:'full'},
          {path: 'settings', component: AdminSettingsComponent, canActivate: [IsOrgActiveGuard]},
          {path: 'billing', component: AdminBillingComponent},
          {path: 'users', component: AdminUsersComponent, canActivate: [IsOrgActiveGuard]},
          {path: 'marketplace', component: AdminMarketplaceComponent, canActivate: [IsOrgActiveGuard]},
        ]
      },
      {
        path: 'superuser',
        component: SuperUserComponent,
        canActivate: [IsSuperUserGuard, IsOrgActiveGuard],
        runGuardsAndResolvers: 'always',
        children:[
          {path: '', redirectTo: 'organizations', pathMatch:'full'},
          {path: 'programs', component: SuperUserProgramComponent},
          {path: 'assessmentTooltips', component: AssessmentTooltipListComponent},
          {path: 'assessmentTooltips/edit/:id', component: AddEditAssessmentTooltipComponent},
          {path: 'organizations', component: OrganizationListComponent},
          {path: 'organizations/add', component: AddEditOrganizationComponent},
          {path: 'organizations/edit/:id', component: AddEditOrganizationComponent},
          {path: 'loadingLevelsPrograms', component: LoadingLevelsProgramsComponent},
          {path: 'rebuild-reporting', component: RebuildReportingComponent},
          {path: 'users', component: AllUserListComponent},
          {path: 'stats', component: SuperuserStatsComponent},
          {path: 'surveys', component: SurveyListComponent},
        ]
      },
      {
        path: 'message-list',
        component: MessageListComponent,
        canActivate: [ IsOrgActiveGuard]
      },
      {
        path: 'user-profile',
        component: MyProfileComponent,
        canActivate: [IsOrgActiveGuard],
      },
      {
        path: 'user-notifications',
        component: NotificationsComponent,
        canActivate: [IsOrgActiveGuard],
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
        canActivate: [IsOrgActiveGuard],
      },
      {
        path: 'runner', component: RunnerContainerComponent,
        canActivate: [RoleGuard],
        canActivateChild: [IsUserActiveGuard],
        runGuardsAndResolvers: 'always',
        data: { roles: [UserRole.Runner], allowInactiveUser: true },
        children:[
          { path: '', redirectTo: 'dashboard', pathMatch:'full'},
          { path: 'calendar', component: RunnerViewComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'dashboard', component: RunnerDashboardComponent, data: { allowInactiveUser: false }},
          { path: 'workout/:id', component: RunnerWorkoutComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'my-library', component: RunnerTrainingIdeasComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'daily-statuses', component: RunnerDailyStatusesListComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'training-volume', component: RunnerTrainingVolumeListComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'acute-chronic', component: RunnerAcuteChronicListComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          { path: 'plans', component: RunnerPlansComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          {path: 'journal', component: RunnerJournalComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }},
          {
            path: 'assessments',
            component: AssessmentsContainerComponent, data: { allowInactiveUser: true },
            children:[
              {path: '', redirectTo: 'list', pathMatch:'full'},
              {path: 'list', component: RunnerAssessmentsComponent, data: { allowInactiveUser: true }},
              {path: 'analysis/view/:id', component: ViewGaitAnalysisComponent, data: { allowInactiveUser: true }},
              {path: 'analysis-walking/view/:id', component: ViewGaitAnalysisWalkingComponent, data: { allowInactiveUser: true } },
              {path: 'gait-comparison/view/:rid/:secrid', component: ViewGaitComparisonReportComponent, data: { allowInactiveUser: true } },
              {path: 'rra-assessment/view/:id', component: ViewAssessmentComponent, data: { allowInactiveUser: true }},
              {path: 'gc-assessment/view/:id', component: ViewGaitCapabilityAssessmentComponent, data: { allowInactiveUser: true }},
              {path: 'loading-levels/view/:id', component: ViewLoadingLevelsComponent, data: { allowInactiveUser: true }},
              {path: '2d-assessment/view/:id', component: View2DAssessmentComponent, data: { allowInactiveUser: true }},
              {path: 'af-fitness/view/:id', component: ViewAFFitnessComponent, data: { allowInactiveUser: true }},
              {path: 'af-fitness-assessment/add', component: AddAirForceFitnessComponent, canActivate: [IsOrgActiveGuard],  canDeactivate: [CanDeactivateGuard], data: { allowInactiveUser: false }},
              {path: 'surveys/add/:id', component: RunnerAddSurveyComponent, canActivate: [IsOrgActiveGuard], data: { allowInactiveUser: false }}
            ]
          },
        ]
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
      },
    ],
  },
  {
    path: 'hammer-and-axe',
    component: HAXProgramsLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/hax-programs-layout/hax-programs-layout.module').then(m => m.HAXProgramsLayoutModule),
      },
    ],
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/public-layout/public-layout.module').then(m => m.PublicLayoutModule),
      },
    ],
  },
  {
    path: 'marketplace/:orgURLSlug',
    component: OrgMarketplaceLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/org-marketplace-layout/org-marketplace-layout.module').then(m => m.OrgMarketplaceLayoutModule),
      },
    ],
  },
  {
    path: 'global-marketplace',
    component: GlobalMarketplaceLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/global-marketplace-layout/global-marketplace-layout.module').then(m => m.GlobalMarketplaceLayoutModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];


@NgModule({
    declarations: [
        CoachesDashboardComponent,
        ClientsComponent,
        AdminSettingsComponent,
        LibraryComponent,
        ProgramsComponent,
        AddClientComponent,
        RouterContainerComponent,
        RunnerAssessmentsComponent,
        ListAssessmentsComponent,
        RunnerDashboardComponent,
        ViewGaitAnalysisComponent,
        ViewGaitAnalysisWalkingComponent,
        ViewGaitComparisonReportComponent,
        MyProfileComponent,
        AddEditAssessmentComponent,
        AddEditGaitCapacityAssessmentComponent,
        GaitCapabilityAssessmentDetailsComponent,
        StatusBarComponent,
        RunnerContainerComponent,
        AddEditExerciseComponent,
        ExercisesComponent,
        WorkoutsComponent,
        AdminBillingComponent,
        AdministrationComponent,
        RunnerWorkoutComponent,
        AdminUsersComponent,
        RunnerViewComponent,
        ExerciseListComponent,
        AddEditProgramComponent,
        EditExerciseComponent,
        AddEditWorkoutComponent,
        ExerciseDropdownControl,
        SecsToHourMinSecPipe,
        WorkoutDropdownControl,
        RunnerDropdownControl,
        AssignProgramComponent,
        ClientsContainerComponent,
        ClientCalendarComponent,
        CalendarWorkoutDialogComponent,
        ClientsListContainerComponent,
        SuperUserComponent,
        CompleteRegistrationComponent,
        SuperUserProgramComponent,
        ProgramDropdownControl,
        AssessmentProgramDialogComponent,
        GaitCapabilityAssessmentProgramDialogComponent,
        WorkoutItemViewComponent,
        WorkoutItemDetailViewComponent,
        SingularPipe,
        IntegrationsComponent,
        AssessmentTooltipListComponent,
        AddEditAssessmentTooltipComponent,
        MessageListComponent,
        ExerciseToolTipModalComponent,
        GaitReportVideoPopupComponent,
        GaitComparisonReportComponent,
        GaitReportDiagramPopupComponent,
        RunnerWorkoutViewComponent,
        RunnerWorkoutModalComponent,
        CalendarDayDetailsComponent,
        RunnerProgramDetailsComponent,
        ClientDashboardComponent,
        RunnerDashboardViewComponent,
        CompletionPercentRangeColorPipe,
        ConfirmModalComponent,
        BreadcrumbsComponent,
        ImageUploadModalComponent,
        StateDropdownControl,
        ViewAssessmentComponent,
        ViewGaitCapabilityAssessmentComponent,
        AssessmentResultDetailsComponent,
        GaitCapabilityAssessmentResultDetailsComponent,
        AssessmentListViewComponent,
        AssessmentDetailsComponent,
        GaitAnalysisDetailsComponent,
        GaitAnalysisWalkingDetailsComponent,
        GaitAnalysisResultDetailsComponent,
        GaitAnalysisWalkingResultDetailsComponent,
        ExercisePreviewComponent,
        OrganizationListComponent,
        AddEditOrganizationComponent,
        DailyStatusModalComponent,
        RunnerDailyStatusesComponent,
        BooleanToTextPipe,
        BooleanToYesNoPipe,
        UserProfileComponent,
        ClientProfileComponent,
        CoachDropdownControl,
        WorkoutViewComponent,
        WorkoutViewModalComponent,
        ExternalWorkoutRouteMapComponent,
        ClientWorkoutComponent,
        ExternalWorkoutSummaryComponent,
        MinutesDecimalToStringPipe,
        ExternalElevationChartComponent,
        ExternalHeartRateChartComponent,
        ExternalPaceChartComponent,
        ExternalCadenceChartComponent,
        CustomEditorControl,
        ExternalStrideLengthChartComponent,
        RestingHeartRateChartComponent,
        AddLoadingLevelsComponent,
        LoadingLevelsProgramsComponent,
        LoadingLevelsDetailsComponent,
        LoadingLevelsResultDetailsComponent,
        ViewLoadingLevelsComponent,
        LoadingLevelsProgramDialogComponent,
        AssessmentNotesDialogComponent,
        ActivateSubscription,
        UpdateCard,
        VideoUploadComponent,
        Add2DAssessmentComponent,
        ImageUploadComponent,
        TwoDimensionalAssessmentResultDetailsComponent,
        TwoDimensionalAssessmentDetailsComponent,
        View2DAssessmentComponent,
        TwoDimensionalProgramDialogComponent,
        MultiOptionControl,
        RunningCategoryChangeDialogComponent,
        GaitReportComparisonDialogComponent,
        ReportsComponent,
        ReportCompletionPercentageComponent,
        CoachAthleteGroupDropdownControl,
        ReportTrainingVolumeComponent,
        RunnerWorkoutConfirmationComponent,
        AcuteChronicRatioColorPipe,
        DonutPieChartComponent,
        AddEditWorkoutComponentComponent,
        WorkoutAddModalComponent,
        ShareWorkoutDialogComponent,
        ClientTrainingIdeasComponent,
        RunnerMyLibraryViewComponent,
        RunnerTrainingIdeasComponent,
        WorkoutAddToCalendarComponent,
        SendMessageModalComponent,
        IntensityRPERangeChartComponent,
        DailyStatusListComponent,
        ClientDailyStatusesComponent,
        RunnerDailyStatusesListComponent,
        ClientTrainingVolumeComponent,
        TrainingVolumeListComponent,
        RunnerTrainingVolumeListComponent,
        OrganizationInactiveComponent,
        AddAirForceFitnessComponent,
        AFFitnessResultDetailsComponent,
        AFFitnessDetailsComponent,
        ViewAFFitnessComponent,
        ManualWorkoutModalComponent,
        NotificationsComponent,
        ListTrainingLoadPlannerComponent,
        AddEditPlannerComponent,
        TrainingLoadPlanCloneDialogComponent,
        DateRangeDialogComponent,
        TrainingVolumeTableComponent,
        AddEditRacePlanComponent,
        ListRacePlanComponent,
        RacePlanCloneDialogComponent,
        AcuteChronicRatioTextPipe,
        RunnerPlansComponent,
        RacePlanViewModalComponent,
        TrainingPlanViewModalComponent,
        ClientAcuteChronicComponent,
        AcuteChronicListComponent,
        RunnerAcuteChronicListComponent,
        CustomCalendarComponent,
        MinutesSecondsTextbox,
        DeleteCalendarEventDialogComponent,
        MoveCalendarEventDialogComponent,
        CalendarEventToggleCompleteDialogComponent,
        TodaysWorkoutsComponent,
        CustomInputPersistedControl,
        flagEnumComparePipe,
        AthleteCalendarComponent,
        TeamsContainerComponent,
        TeamsDashboardComponent,
        TeamContainerComponent,
        TeamDashboardComponent,
        TeamCalendarComponent,
        TeamDropdownControl,
        AssignTeamProgramComponent,
        ManageTeamDialogComponent,
        RunnerBulkSelectControl,
        CoachMultiselectControl,
        TeamMultiselectControl,
        TeamProgramDetailsComponent,
        ExternalLapChartComponent,
        RebuildReportingComponent,
        CopyCalendarEventDialogComponent,
        WorkoutProgressionsComponent,
        AddEditWorkoutProgressionComponent,
        WorkoutProgressionPreviewComponent,
        WorkoutLevelChangeModalComponent,
        AdminUserAddEditModalComponent,
        CopyProgramEventDialogComponent,
        AddProgramEventDialogComponent,
        AllUserListComponent,
        SuperuserStatsComponent,
        AssessmentsContainerComponent,
        RunnerAddSurveyComponent,
        SurveyListComponent,
        EditSurveyModalComponent,
        SuperuserProgramDropdownControl,
        ChangePasswordDialogComponent,
        ProgramRescheduleDialogComponent,
        ExternalSpeedChartComponent,
        CoachLibraryDropdownPersistedControl,
        PendingInvitationsComponent,
        UserDropdownControl,
        TermsAndConditionsComponent,
        PrintCalendarDialogComponent,
        WorkoutViewPDFComponent,
        PrintWorkoutDialogComponent,
        PrivacyPolicyComponent,
        ProgramQuickWorkoutViewModalComponent,
        QuickWorkoutViewPDFComponent,
        QuickWorkoutViewComponent,
        QuickWorkoutViewModalComponent,
        AssessmentPDFDialogComponent,
        AzureFileUploadControl,
        GaitAnalysisUpdateDialogComponent,
        RunnerReadinessReportUpdateUpdateDialogComponent,
        GaitCapacityReportUpdateDialogComponent,
        GaitReportUpdateDialogComponent,
        MatchSyncedWorkoutDialogComponent,
        AdminMarketplaceComponent,
        FlagEnumButtonsControl,
        ProgramAddToCalendarComponent,
        GlobalMarketplaceProgramListComponent,
        ExerciseProgressionPreviewComponent,
        ExerciseProgressionsComponent,
        AddEditExerciseProgressionComponent,
        ExerciseAddModalComponent,
        ExerciseLevelChangeModalComponent,
        ExerciseViewComponent,
        PrintExerciseDialogComponent,
        AcuteChronicDonutChartComponent,
        CopyCalendarWeekDialogComponent,
        ShareExerciseDialogComponent,
        GaitAnalysisSharedComponent,
        GaitAnalysisWalkingSharedComponent,
        GaitComparisonReportSharedComponent,
        GaitCapabilityAnalysisSharedComponent,
        RunnerMyMessagesViewComponent,
        ClientMessagesComponent,
        ClientNotificationsComponent,
        ClientJournalComponent,
        RunnerJournalViewComponent,
        GeneralJournalDialogComponent,
        RunnerJournalComponent,
    ],
    imports: [
        SharedModule,
        BrowserModule,
        ImageCropperModule,
        DialogsModule,
        SortableModule,
        DirectivesModule,
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            useHash: false,
            scrollOffset: [0, 64],
            relativeLinkResolution: 'legacy'
        }),
        AlertModule.forRoot(),
        NgCircleProgressModule.forRoot(),
        NgxQRCodeModule,
    ],
    exports: [RouterModule, SecsToHourMinSecPipe, MinutesDecimalToStringPipe]
})
export class AppRoutingModule {}
